import { SvgIconProps } from '@/types/helpers'

export default function Verified(props: SvgIconProps) {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_4189_1305)">
        <path
          d="M16.7581 13.0065C17.1725 14.5698 17.0283 15.907 16.2177 16.7177C15.407 17.5283 14.0698 17.6725 12.5065 17.2581C11.6958 18.6573 10.647 19.5 9.5 19.5C8.35504 19.5 7.30615 18.6573 6.49347 17.2581C4.93015 17.6725 3.59303 17.5283 2.78234 16.7177C1.97166 15.907 1.82754 14.5698 2.24189 13.0065C0.84271 12.1938 0 11.145 0 10C0 8.85504 0.84271 7.80615 2.24189 6.99347C1.82754 5.43015 1.97166 4.09303 2.78234 3.28234C3.59303 2.47166 4.93015 2.32754 6.49347 2.74189C7.30415 1.34271 8.35303 0.5 9.5 0.5C10.645 0.5 11.6938 1.34271 12.5065 2.74189C14.0698 2.32754 15.407 2.47166 16.2177 3.28234C17.0283 4.09303 17.1725 5.43015 16.7581 6.99347C18.1573 7.80415 19 8.85303 19 10C19 11.147 18.1553 12.1938 16.7581 13.0065Z"
          fill="currentFill"
        />
        <path
          d="M6 10.2692C6.83333 11.1923 8.5 13.5 8.5 13.5L13 7.5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4189_1305">
          <rect
            width="19"
            height="19"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
