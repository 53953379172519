export const getAvatarURL = (style: string, seed: string) =>
  `https://api.dicebear.com/7.x/${style}/svg?seed=${seed}`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getUserAvatarURL = (seed: string, userType?: string) => {
  if (userType === 'user') {
    return `/client_user.svg`
  }

  if (userType === 'creator') {
    return `/creator_user.svg`
  }

  return `/profile_default.svg`
}
// getAvatarURL('thumbs', seed)
