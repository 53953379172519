import { SvgIconProps } from '@/types/helpers'

export default function CheckAll(props: SvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <path
        d="M11.2427 12.7573C11.6333 13.1478 12.2664 13.1478 12.657 12.7573L16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289L11.2427 11.343C10.8522 11.7336 10.8522 12.3667 11.2427 12.7573Z"
        fill="currentFill"
      />
      <path
        d="M7.62567 17.106C7.96321 17.0343 8.03772 16.6233 7.79372 16.3793L6.30512 14.8907C6.30102 14.8867 6.29694 14.8827 6.29289 14.8787L3.70711 12.2929C3.31658 11.9024 2.68342 11.9024 2.29289 12.2929C1.90237 12.6834 1.90237 13.3166 2.29289 13.7071L4.87868 16.2929C5.62454 17.0387 6.66541 17.3098 7.62567 17.106Z"
        fill="currentFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7071 7.24291C22.0976 7.63343 22.0976 8.26659 21.7071 8.65712L14.0713 16.2929C12.8998 17.4645 11.0003 17.4645 9.82869 16.2929L7.24291 13.7071C6.85238 13.3166 6.85238 12.6834 7.24291 12.2929C7.63343 11.9024 8.26659 11.9024 8.65712 12.2929L11.2429 14.8787C11.6334 15.2692 12.2666 15.2692 12.6571 14.8787L20.2929 7.24291C20.6834 6.85238 21.3166 6.85238 21.7071 7.24291Z"
        fill="currentFill"
      />
    </svg>
  )
}
